import React, { CSSProperties, FC } from "react"
import { Link } from "gatsby"

const backgroundColorSwitch = (variant) => {
    switch (variant) {
        case "orange":
            return "#FF955E"
        case "purple":
            return "#654EA3"
        case "white":
            return "#FFFFFF"
        default:
            return "#654EA3"
    }
}

interface IProps {
    variant: string
    href: string
    style?: CSSProperties
}

const InternalButtonLink: FC<IProps> = ({ children, variant, href, style }) => {
    const backgroundColor = backgroundColorSwitch(variant)

    const fontColor = variant === "white" ? "#654EA3" : "#FFFFFF"

    const buttonLinkStyle: CSSProperties = {
        backgroundColor: backgroundColor,
        color: fontColor,
        textDecoration: "none",
        textTransform: "uppercase",
        boxShadow: "0px 0.5px 1.5px rgba(0, 0, 0, 0.08), 0px 1px 4px rgba(0, 0, 0, 0.08)",
        borderRadius: 4,
        fontSize: 14,
        fontWeight: 500,
        fontFamily: "Ubuntu",
        lineHeight: "16px",
        letterSpacing: "1.25px",
        padding: "11px 16px",
        cursor: "pointer",
        ...style
    }

    return (
        <Link style={buttonLinkStyle} to={href}>
            {children}
        </Link>
    )
}

export default InternalButtonLink
