/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { FC } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Theme } from "@material-ui/core"

import "./layout.css"

import Header from "./Header"
import Footer from "./Footer"
import NuliaTheme from "./NuliaTheme";

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        fontFamily: "'Open Sans', sans-serif",
        fontSize: 16,
        lineHeight: "24px",
        letterSpacing: "0.5px",
        color: "rgba(0, 0, 0, 0.8)",
        margin: "0 auto",
        overflowX: "hidden",
        "& a:link": {
            color: "#654EA3"
        }
    },
    innerWrapper: {
        margin: `0 auto`,
        maxWidth: 1600,
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08), 0px 5px 16px rgba(0, 0, 0, 0.08)",
        paddingTop: 64
    }
}))

const Layout: FC = ({ children }) => {
    const classes = useStyles()

    return (
        <NuliaTheme>
            <div className={classes.wrapper}>
                <Header />
                <div className={classes.innerWrapper}>
                    <main>{children}</main>
                    <Footer />
                </div>
            </div>
        </NuliaTheme>
    )
}

export default Layout
