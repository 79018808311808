export const HOME = "/";
export const SOLUTION = "/solution";
//export const ABOUT = "/about-us";
export const NEWS = "/news";
export const STORIES = "/user-stories";
export const DEMO = "/demo";
export const WORKSHOP = "/workshop";
export const RESOURCES = "/resources";
export const PRIVACY = "/privacy";
export const TERMS = "/terms";
export const TRIAL = "/trial";

export const FACEBOOK = "https://www.facebook.com/nuliacloud/";
export const TWITTER = "https://twitter.com/nuliacloud";
export const INSTAGRAM = "https://www.instagram.com/nuliacloud/";
export const LINKEDIN = "https://www.linkedin.com/company/nulia";

export const PLATFORM = "https://nuliaworks.com";
export const PARTNER = "https://partner.nuliaworks.com";
