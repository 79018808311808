import { Link } from "gatsby"
import React, { FC } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Theme } from "@material-ui/core"
import {
    FACEBOOK,
    HOME,
    INSTAGRAM,
    LINKEDIN,
    NEWS,
    PARTNER,
    PLATFORM,
    SOLUTION,
    STORIES,
    TWITTER,
    WORKSHOP
} from "../utils/routes"
import NuliaLogo from "./icons/NuliaLogo"
import {Facebook, Instagram, Linkedin, Twitter} from "react-feather";
import {Colors} from "../utils/colors";

const useStyles = makeStyles((theme: Theme) => ({
    footer: {
        position: "relative"
    },
    wrapper: {
        margin: `0 auto`,
        maxWidth: 1200,
        padding: `40px 0 36px 0`,
        display: "flex",
        alignItems: "left",
        [theme.breakpoints.down(959)]: {
            flexDirection: "column",
            padding: 40
        }
    },
    navigation: {
        "& ul": {
            listStyleType: "none",
            margin: 0,
            padding: 0,
            overflow: "hidden",
            fontSize: 14,
            fontWeight: 500,
            fontFamily: "Ubuntu",
            lineHeight: "16px",
            letterSpacing: "1.25px",
            display: "flex",
            [theme.breakpoints.down(959)]: {
                flexDirection: "column"
            }
        },
        "& li": {
            marginBottom: 16,
            display: "flex",
            [theme.breakpoints.down(959)]: {
                flexDirection: "column"
            }
        },
        "& a": {
            textDecoration: "none",
            color: "#654EA3"
        }
    },
    linkGroup: {
        display: "flex",
        flexDirection: "column",
        marginLeft: 64,
        [theme.breakpoints.down(959)]: {
            marginLeft: 0,
            marginTop: 32
        }
    },
    contactInfo: {
        marginLeft: 60,
        "& address": {
            fontFamily: "Open Sans",
            fontSize: 14,
            fontStyle: "normal",
            lineHeight: "20px",
            letterSpacing: "0.25px"
        },
        [theme.breakpoints.down(959)]: {
            marginLeft: 0,
            marginTop: 16
        }
    },
    socialMedia: {
        display: "flex",
        "& a": {
            textDecoration: "none",
            textTransform: "uppercase",
            borderRadius: 4,
            fontSize: 14,
            fontWeight: 500,
            lineHeight: "16px",
            letterSpacing: "1.25px",
            padding: "9px 16px",
            marginRight: 16,
            cursor: "pointer",

            "& svg": {
                marginTop: 2
            }
        }
    }
}))

const Footer: FC = () => {
    const classes = useStyles()

    return (
        <footer className={classes.footer}>
            <div className={classes.wrapper}>
                <Link style={{ height: 36 }} to="/">
                    <NuliaLogo />
                </Link>
                <nav className={classes.navigation} aria-label="Secondary">
                    <ul>
                        <div className={classes.linkGroup}>
                            <li>
                                <Link to={HOME}>HOME</Link>
                            </li>
                            <li>
                                <Link to={SOLUTION}>SOLUTION</Link>
                            </li>
                            <li>
                                <Link to={STORIES}>USER STORIES</Link>
                            </li>
                            <li>
                                <Link to={NEWS}>NEWS</Link>
                            </li>
                            <li>
                                <a
                                    href={PLATFORM}
                                >
                                    LOGIN
                                </a>
                            </li>
                            <li>
                                <Link to={WORKSHOP}>FREE WORKSHOP</Link>
                            </li>
                        </div>
                        <div className={classes.linkGroup}>
                            <li>
                                <Link to="/terms">TERMS &amp; CONDITIONS</Link>
                            </li>
                            <li>
                                <Link to="/privacy">PRIVACY</Link>
                            </li>
                            <li>
                                <a
                                    href={PARTNER}
                                >
                                    PARTNER PORTAL
                                </a>
                            </li>
                        </div>
                    </ul>
                </nav>
                <div className={classes.contactInfo}>
                    <h2
                        style={{
                            fontSize: 16,
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                            marginBottom: 16
                        }}
                    >
                        Contact Us
                    </h2>
                    <address>
                        2852 Willamette Street, #603
                        <br />
                        Eugene, OR 97405 USA
                        <br />
                        <a href="mailto:info@nulia.com">info@nulia.com</a>
                    </address>
                    <div className={classes.socialMedia}>
                        <a
                            href={LINKEDIN}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Follow us on LinkedIn"
                            style={{ backgroundColor: "#0077B5" }}
                        >
                            <Linkedin style={{ color: Colors.white }} />
                        </a>
                        <a
                            href={FACEBOOK}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Follow us on Facebook"
                            style={{ backgroundColor: "#4267B2" }}
                        >
                            <Facebook style={{ color: Colors.white }} />
                        </a>
                        <a
                            href={TWITTER}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Follow us on Twitter"
                            style={{ backgroundColor: "#1DA1F2" }}
                        >
                            <Twitter style={{ color: Colors.white }} />
                        </a>
                        <a
                            href={INSTAGRAM}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Follow us on Instagram"
                            style={{ backgroundColor: "#0095F6" }}
                        >
                            <Instagram style={{ color: Colors.white }} />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
