import React, { FC } from "react"

const NuliaLogo: FC = () => (
    <svg role="img" aria-labelledby="nulia-logo-title" width="174" height="36" viewBox="0 0 174 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title id="nulia-logo-title">Nulia Logo</title>
        <path fillRule="evenodd" clipRule="evenodd" d="M26.1 34.7h-4.463V10.25L38.4 26.337c3.038 2.55 5.888 3.788 8.7 3.788 6.525 0 11.85-5.325 11.85-11.85 0-6.525-5.325-11.85-11.85-11.85h-3.712V2H47.1c9 0 16.313 7.313 16.313 16.313S56.1 34.624 47.1 34.624c-3.938 0-7.725-1.612-11.625-4.913l-.113-.075L26.1 20.75V34.7zm-6.825-.038h-2.962C7.313 34.663 0 27.35 0 18.35S7.313 2.037 16.313 2.037c.492 0 .984.033 1.507.068l.105.007C20.887 2.412 23.7 3.5 26.1 5.3l.15.112 1.65 1.463 8.813 8.175V2.037h4.5V25.25l-16.35-15.075-1.538-1.35c-1.725-1.275-3.75-2.025-5.85-2.25a12.095 12.095 0 00-1.163-.075c-6.525.037-11.85 5.363-11.85 11.887 0 6.526 5.325 11.85 11.85 11.85h2.963v4.426zM77.85 6.838l8.063 14.138-.188-14.138h4.238v19.688h-4.8l-8.475-14.887.187 14.887h-4.237V6.837h5.212zM106.575 23.6c-3.413 0-4.425-1.463-4.425-4.95V6.838h-4.125v11.887c0 2.475.225 4.2 1.125 5.287 1.612 1.913 4.012 2.7 7.425 2.7 3.413 0 5.775-.787 7.387-2.7.901-1.05 1.163-2.774 1.163-5.287V6.837H111V18.65c0 3.488-1.012 4.95-4.425 4.95zm18 .9c-1.5-1.575-1.8-3.3-1.8-5.925V6.838h4.125v11.55c0 1.612.038 2.812.675 3.562.863 1.05 2.062 1.275 3.825 1.275h4.875v3.262h-5.25c-2.813.076-4.688-.15-6.45-1.987zM142.65 6.837v19.726l4.312-.038V6.837h-4.312zm22.575 0l8.4 19.688h-4.613l-1.8-4.688h-8.549l-1.763 4.688h-4.388l8.325-19.688h4.388zm-5.25 11.513h5.887l-3-7.575-2.887 7.575z" fill="#654EA3"/>
        <mask id="a" maskUnits="userSpaceOnUse" x="0" y="2" width="174" height="33">
            <path fillRule="evenodd" clipRule="evenodd" d="M26.1 34.7h-4.463V10.25L38.4 26.337c3.038 2.55 5.888 3.788 8.7 3.788 6.525 0 11.85-5.325 11.85-11.85 0-6.525-5.325-11.85-11.85-11.85h-3.712V2H47.1c9 0 16.313 7.313 16.313 16.313S56.1 34.624 47.1 34.624c-3.938 0-7.725-1.612-11.625-4.913l-.113-.075L26.1 20.75V34.7zm-6.825-.038h-2.962C7.313 34.663 0 27.35 0 18.35S7.313 2.037 16.313 2.037c.492 0 .984.033 1.507.068l.105.007C20.887 2.412 23.7 3.5 26.1 5.3l.15.112 1.65 1.463 8.813 8.175V2.037h4.5V25.25l-16.35-15.075-1.538-1.35c-1.725-1.275-3.75-2.025-5.85-2.25a12.095 12.095 0 00-1.163-.075c-6.525.037-11.85 5.363-11.85 11.887 0 6.526 5.325 11.85 11.85 11.85h2.963v4.426zM77.85 6.838l8.063 14.138-.188-14.138h4.238v19.688h-4.8l-8.475-14.887.187 14.887h-4.237V6.837h5.212zM106.575 23.6c-3.413 0-4.425-1.463-4.425-4.95V6.838h-4.125v11.887c0 2.475.225 4.2 1.125 5.287 1.612 1.913 4.012 2.7 7.425 2.7 3.413 0 5.775-.787 7.387-2.7.901-1.05 1.163-2.774 1.163-5.287V6.837H111V18.65c0 3.488-1.012 4.95-4.425 4.95zm18 .9c-1.5-1.575-1.8-3.3-1.8-5.925V6.838h4.125v11.55c0 1.612.038 2.812.675 3.562.863 1.05 2.062 1.275 3.825 1.275h4.875v3.262h-5.25c-2.813.076-4.688-.15-6.45-1.987zM142.65 6.837v19.726l4.312-.038V6.837h-4.312zm22.575 0l8.4 19.688h-4.613l-1.8-4.688h-8.549l-1.763 4.688h-4.388l8.325-19.688h4.388zm-5.25 11.513h5.887l-3-7.575-2.887 7.575z" fill="#fff"/>
        </mask>
    </svg>

)

export default NuliaLogo
