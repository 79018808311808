import { createTheme, ThemeProvider } from '@material-ui/core';
import * as React from 'react';
import { FC } from 'react';
import { Colors } from '../utils/colors';
import { CssBaseline } from '@material-ui/core';

interface IProps {
    clientType?: string;
}

const NuliaTheme: FC<IProps> = ({ clientType, children }) => {
    const theme = createTheme({
        breakpoints: {
            values: {
                          xs: 0,
                          sm: 600,
                          md: 959,
                          lg: 1200,
                          xl: 1920,
                      }
        },
        typography: {
            fontFamily: 'Ubuntu',
            fontSize: 16,
            button: {
                fontFamily: 'Ubuntu',
                fontSize: 16,
                fontWeight: 500,
                fontStyle: 'normal',
                lineHeight: '24px',
                letterSpacing: 0.15,
                color: Colors.textPrimary,
                margin: 0,
            },
            h1: {
                fontFamily: 'Ubuntu',
                fontSize: 96,
                fontWeight: 400,
                fontStyle: 'normal',
                lineHeight: '104px',
                letterSpacing: -1.5,
                color: Colors.textPrimary,
                margin: 0,
            },
            h2: {
                fontFamily: 'Ubuntu',
                fontSize: 60,
                fontWeight: 400,
                fontStyle: 'normal',
                lineHeight: '64px',
                letterSpacing: -0.5,
                color: Colors.textPrimary,
                margin: 0,
            },
            h3: {
                fontFamily: 'Ubuntu',
                fontSize: 48,
                fontWeight: 400,
                fontStyle: 'normal',
                lineHeight: '56px',
                color: Colors.textPrimary,
                margin: 0,
            },
            h4: {
                fontFamily: 'Ubuntu',
                fontSize: 32,
                fontWeight: 600,
                fontStyle: 'normal',
                lineHeight: '40px',
                letterSpacing: 0.25,
                color: Colors.textPrimary,
                margin: 0,
            },
            h5: {
                fontFamily: 'Ubuntu',
                fontSize: 24,
                fontWeight: 600,
                fontStyle: 'normal',
                lineHeight: '28px',
                letterSpacing: 'normal',
                color: Colors.textPrimary,
                margin: 0,
            },
            h6: {
                fontFamily: 'Ubuntu',
                fontWeight: 400,
                fontStyle: 'normal',
                fontSize: 20,
                lineHeight: '24px',
                letterSpacing: 0.25,
                color: Colors.textPrimary,
                margin: 0,
            },
            body1: {
                fontFamily: 'Open Sans',
                fontSize: 16,
                fontWeight: 400,
                fontStyle: 'normal',
                lineHeight: '24px',
                letterSpacing: 0,
                color: Colors.textSecondary,
                margin: 0,
            },
            body2: {
                fontFamily: 'Open Sans',
                fontSize: 14,
                fontWeight: 400,
                fontStyle: 'normal',
                lineHeight: '21px',
                letterSpacing: 0.25,
                color: Colors.textSecondary,
                margin: 0,
            },
            subtitle1: {
                fontFamily: 'Ubuntu',
                fontSize: 16,
                fontWeight: 500,
                fontStyle: 'normal',
                lineHeight: '24px',
                letterSpacing: 0.15,
                color: Colors.textPrimary,
                margin: 0,
            },
            subtitle2: {
                fontFamily: 'Ubuntu',
                fontSize: 14,
                fontWeight: 400,
                fontStyle: 'normal',
                lineHeight: '24px',
                letterSpacing: 0.1,
                color: Colors.textPrimary,
                margin: 0,
            },
            overline: {
                fontFamily: 'Ubuntu',
                fontSize: 12,
                fontWeight: 400,
                fontStyle: 'normal',
                lineHeight: '16px',
                letterSpacing: 2,
                color: Colors.textSecondary,
                textTransform: 'uppercase',
                margin: 0,
            },
            caption: {
                fontFamily: 'Open Sans',
                fontSize: 12,
                fontWeight: 400,
                fontStyle: 'normal',
                lineHeight: '20px',
                letterSpacing: 0.5,
                color: Colors.textSecondary,
                margin: 0,
            },
        },
        palette: {
            primary: {
                light: Colors.lightPurple,
                main: Colors.purple,
                dark: Colors.darkPurple,
                contrastText: '#fff',
            },
            secondary: {
                light: Colors.lightPurple,
                main: Colors.purple,
                dark: Colors.darkPurple,
                contrastText: '#fff',
            },
        },
        overrides: {
            MuiIconButton: {
                root: {
                    padding: 0,
                },
            },
            MuiButtonBase: {
                root: {
                    '&$focusVisible': {
                        outline: 0,
                        boxShadow: `0px 0px 0px 1px ${Colors.purple}`,
                    },
                },
            },
            MuiLink: {
                root: {
                    '&$focusVisible': {
                        background:
                            'linear-gradient(0deg, rgba(101, 78, 163, 0.08), rgba(101, 78, 163, 0.08)), #FFFFFF',
                        boxShadow: `0px 0px 0px 1px ${Colors.purple}`,
                        borderRadius: 2,
                        outline: 0,
                    },
                },
            },
            MuiAccordionSummary: {
                root: {
                    padding: '2px 16px',
                    '&$focused': {
                        backgroundColor: 'initial',
                        borderRadius: 2,
                    },
                },
            },
            MuiTableCell: {
                root: {
                    padding: 0,
                },
            },
            MuiInputBase: {
                root: {
                    '&$focused': {
                        outline: `${Colors.purple} auto 1px;`,
                    },
                },
            },
        },
        props: {
            MuiButtonBase: {
                disableRipple: true,
            },
        },
    });
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
};

export default NuliaTheme;
