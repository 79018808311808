export const Colors = {
    purple: "#654ea3",
    lightPurple: "#f3f1f8",
    darkPurple: "#45356f",
    purpleHover: "#c2beda",
    background: "#fafafa",
    white: "#ffffff",
    textPrimary: "#333333",
    textSecondary: "#666666",
    green: "#b0cb3e",
    yellow: "#ffcc55",
    red: "#FE6C6C",
    progressUnassigned: "#d6d6d6",
    disabled: "rgba(0,0,0,0.16)",
    iconDefault: "#999999",
    iconHover: "#f3f1f8",
    unachievedSkill: "#d6d6d6",
    userSkill: "#ff955e",
    producerSkill: "#dd6862",
    masterSkill: "#349fee",
    black: "#000",
    low: "#023BC6",
    moderate: "#0278E5",
    high: "#349FEE"
}
