import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { FC } from "react"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Drawer from "@material-ui/core/Drawer"

import ButtonLink from "./ButtonLink"
import NuliaLogo from "./icons/NuliaLogo"
import InternalButtonLink from "./InternalButtonLink"
import { HOME, NEWS, PLATFORM, SOLUTION, STORIES, WORKSHOP } from "../utils/routes"
import {Menu, X} from "react-feather";
import {makeStyles} from "@material-ui/styles";
import {Theme, useTheme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    header: {
        position: "fixed",
        left: "50%",
        transform: "translateX(-50%)",
        background: "#FFFFFF",
        width: "100vw",
        maxWidth: 1600,
        margin: "0 auto",
        zIndex: 10,
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.08)",
        height: 64,
        display: "flex",
        alignItems: "center"
    },
    wrapper: {
        margin: `0 auto`,
        width: "100vw",
        maxWidth: 1600,
        padding: `0 24px`,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    hamburgerMenuButton: {
        background: "none",
        color: "inherit",
        border: "none",
        padding: 0,
        font: "inherit",
        cursor: "pointer",
        outline: "inherit",
        height: "24px"
    },
    navigation: {
        "& ul": {
            listStyleType: "none",
            margin: 0,
            padding: 0,
            overflow: "hidden",
            fontSize: 12,
            fontWeight: 500,
            fontFamily: "Ubuntu",
            lineHeight: "12px",
            letterSpacing: "1px",
            textTransform: "uppercase",
            display: "flex",
            [theme.breakpoints.down('md')]: {
                fontFamily: "Ubuntu",
                flexDirection: "column",
                fontSize: 14,
                lineHeight: "16px",
                letterSpacing: "1.25px"
            }
        },
        "& li": {
            marginLeft: 50,
            height: 64,
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.down('md')]: {
                marginLeft: 16,
                marginRight: 16,
                height: 40,
                width: "100%"
            }
        },
        "& a": {
            textDecoration: "none",
            "&:hover": {
                color: "#5C4F9C"
            }
        }
    },
    currentPageLink: {
        borderBottom: `2px solid #654EA3`,
        marginTop: 1,
        "& a": {
            color: "#5C4F9C"
        }
    },
    textLink: {
        "& a": {
            color: "rgba(0, 0, 0, 0.64) !important"
        }
    },
    drawer: {
        display: "flex",
        flexDirection: "column",
        width: 192
    },
    nuliaLogoDrawer: {
        "& svg": {
            height: 20,
            width: 97,
            marginTop: 2
        }
    },
    drawerHeading: {
        height: 56,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: 16,
        paddingRight: 16
    },
    drawerCloseButton: {
        background: "none",
        color: "inherit",
        border: "none",
        padding: 0,
        font: "inherit",
        cursor: "pointer",
        outline: "inherit",
        height: "24px"
    }
}))

const Header: FC = () => {
    const theme = useTheme()
    const classes = useStyles(theme)

    const pathname = typeof window !== "undefined" ? window.location.pathname : ""

    const [drawerOpen, setDrawerOpen] = React.useState(false)
    const toggleDrawer = (event) => {
        setDrawerOpen(!drawerOpen)
    }

    const isMdDown = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <header className={classes.header}>
            <div className={classes.wrapper}>
                <Link style={{ height: 36 }} to="/">
                    <NuliaLogo />
                </Link>
                {!isMdDown && (
                    <nav className={classes.navigation} aria-label="Primary">
                        <ul>
                            <li className={pathname === "/" ? classes.currentPageLink : classes.textLink}>
                                <Link to="/">HOME</Link>
                            </li>
                            <li className={pathname === "/product/" || pathname === "/product" || pathname === "/solution" ? classes.currentPageLink : classes.textLink}>
                                <Link to="/solution">SOLUTION</Link>
                            </li>
                            <li className={pathname === "/user-stories" ? classes.currentPageLink : classes.textLink}>
                                <Link to="/user-stories">USER STORIES</Link>
                            </li>
                            <li className={pathname === "/news" ? classes.currentPageLink : classes.textLink}>
                                <Link to="/news">NEWS</Link>
                            </li>
                            <li style={{ marginLeft: 12 }}>
                                <ButtonLink style={{ marginLeft: 12 }} variant="white" href="https://nuliaworks.com/">
                                    LOGIN
                                </ButtonLink>
                            </li>
                            <li style={{ marginLeft: 16 }}>
                                <InternalButtonLink variant="orange" href={WORKSHOP}>
                                    FREE WORKSHOP
                                </InternalButtonLink>
                            </li>
                        </ul>
                    </nav>
                )}
                {isMdDown && (
                    <>
                        <button className={classes.hamburgerMenuButton} aria-label="Menu" onClick={toggleDrawer}>
                            <Menu />
                        </button>
                        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer} className={classes.drawer}>
                            <div className={classes.drawerHeading}>
                                <Link className={classes.nuliaLogoDrawer} to="/">
                                    <NuliaLogo />
                                </Link>
                                <button className={classes.drawerCloseButton} onClick={toggleDrawer}>
                                    <X />
                                </button>
                            </div>
                            <nav className={classes.navigation} aria-label="Primary">
                                <ul>
                                    <li>
                                        <Link to={HOME}>HOME</Link>
                                    </li>
                                    <li>
                                        <Link to={SOLUTION}>SOLUTION</Link>
                                    </li>
                                    <li>
                                        <Link to={STORIES}>USER STORIES</Link>
                                    </li>
                                    <li>
                                        <Link to={NEWS}>NEWS</Link>
                                    </li>

                                    <li style={{ marginBottom: 12, marginLeft: 14 }}>
                                        <ButtonLink variant="white" href={PLATFORM} style={{ width: 170 }}>
                                            LOGIN
                                        </ButtonLink>
                                    </li>
                                    <li style={{ marginLeft: 14 }}>
                                        <InternalButtonLink variant="orange" href={WORKSHOP} style={{ width: 170 }}>
                                            FREE WORKSHOP
                                        </InternalButtonLink>
                                    </li>
                                </ul>
                            </nav>
                        </Drawer>
                    </>
                )}
            </div>
        </header>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string
}

Header.defaultProps = {
    siteTitle: ``
}

export default Header
