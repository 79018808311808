import React, { FC, useEffect, useState } from "react"
import { Button, IconButton, Snackbar, SnackbarContent } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Close } from "@material-ui/icons"
import { Link } from "gatsby"
import ReactGA from "react-ga"

const useStyles = makeStyles({
    caption: {
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 12,
        lineHeight: "20px",
        letterSpacing: "0.5px",
        margin: 0
    },
    textRow: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "space-between"
    }
})

const CookiesSnackbar: FC = () => {
    const classes = useStyles()
    const [checkedStorage, setCheckedStorage] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false)

    useEffect(() => {
        if (!checkedStorage) {
            const allowCookies = localStorage.getItem("ALLOW_COOKIES")
            if (!allowCookies) {
                setOpen(true)
            } else if (allowCookies === "true") {
                ReactGA.initialize(process.env.googleAnalytics as string)
                ReactGA.pageview(window.location.pathname + window.location.search)
            }
            setCheckedStorage(true)
        }
    }, [checkedStorage])

    const accept = () => {
        localStorage.setItem("ALLOW_COOKIES", "true")
        setOpen(false)
    }

    const cancel = () => {
        localStorage.setItem("ALLOW_COOKIES", "false")
        setOpen(false)
    }

    return (
        <Snackbar style={{ width: "100%" }} open={open}>
            <SnackbarContent
                style={{ backgroundColor: "#fff", color: "#000" }}
                message={
                    <div>
                        <div className={classes.textRow}>
                            <p className={classes.caption}>
                                We use technical and analytical cookies to ensure that you have a great experience on
                                our website. By continuing to use our site, you accept these cookies. Please click{" "}
                                <span style={{ fontWeight: "bold" }}>I Agree</span> to allow us to provide the best
                                experience.
                            </p>
                            <IconButton
                                style={{ marginLeft: 16, padding: 0, color: "rgba(0, 0, 0, 0.32)" }}
                                aria-label="close icon"
                                component="span"
                                onClick={cancel}
                            >
                                <Close />
                            </IconButton>
                        </div>
                        <div>
                            <Button
                                style={{
                                    backgroundColor: "#654EA3",
                                    color: "#fff",
                                    fontFamily: "Ubuntu",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    lineHeight: "16px",
                                    letterSpacing: "1.25px",
                                    padding: "10px 16px",
                                    marginRight: 16
                                }}
                                size="small"
                                onClick={accept}
                            >
                                I Agree
                            </Button>
                            <Link
                                to="/privacy"
                                style={{
                                    color: "#654EA3",
                                    fontFamily: "Ubuntu",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    lineHeight: "16px",
                                    letterSpacing: "1.25px",
                                    textDecoration: "none"
                                }}
                            >
                                Privacy Policy
                            </Link>
                        </div>
                    </div>
                }
            />
        </Snackbar>
    )
}

export default CookiesSnackbar
